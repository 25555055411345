import { XMarkIcon } from '@heroicons/react/24/outline';

const ActiveFilterItem = ({ text, onDelete }) => {
  return (
    <div className="bg-snow-900 text-graphite-800 text-xs truncate max-w-[150px] py-[2px] px-1 rounded-[4px] justify-between items-center flex">
      <p className="w-full truncate">{text}</p>
      <XMarkIcon
        className="w-4 h-4 ml-auto cursor-pointer"
        onClick={onDelete}
      />
    </div>
  );
};

export default ActiveFilterItem;
