// import necessary hooks
import { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import {
  PlusCircleIcon,
  ChevronDoubleLeftIcon,
  FolderPlusIcon,
  MagnifyingGlassIcon,
  SpeakerWaveIcon,
  XMarkIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import useWindowSize from 'Hooks/useWindowSize';
import { useChat } from 'Context/Chat/Chat.context';
import DragAndDropFolders from '../DragAndDropFolders/DragAndDropFolders';
import { useFoldersChatStore } from '../DragAndDropFolders/store';
import useTreeDNDChatStore from '../TreeDNDChat/Store';
import useThemeStore from 'Theme/store';
import TextSpeechDialog from 'Components/TextSpeechDialog';
import { Button, Tooltip } from '@mui/material';
import { ArrowPathIcon, TrashIcon } from '@heroicons/react/24/solid';
import DeleteChats from './DeleteChats'; // Import the new bulk delete modal
import DeleteChat from '../../../Features/Core/Components/DeleteChat/DeleteChat';
import useFavoriteMessagesStore from '../../Common/Markdown/FavoriteMessages/store';

const ConversationSide = inject('store')(
  observer(
    ({
      show,
      onClick,
      onSelectChat,
      store,
      currentChatId,
      handleScriptForTTS,
    }) => {
      //context
      const { setShowMobileChats, setShowConversationsSide } = useChat();
      const { width } = useWindowSize();
      const [showTextSpeechDialog, setShowTextSpeechDialog] = useState(false);

      useEffect(() => {
        if (width > 1024) {
          setShowMobileChats(false);
        }
      }, [width]);

      const { theme } = useThemeStore();
      const { setModalOpen } = useFoldersChatStore();
      const createFolder = () => {
        setModalOpen(true);
      };

      const {
        showSearch,
        setShowSearch,
        search,
        setSearch,
        setPage,
        selectedChatIds,
        deleteChatsModalOpen,
        setDeleteChatsModalOpen,
        setSelectedChatIds,
        treeData,
        setTreeData,
      } = useTreeDNDChatStore();

      const [deleted, setDeleted] = useState(false); // Local state for deletion status

      const { setOpenFavoriteMessages } = useFavoriteMessagesStore();

      const ScriptForTTS = () => {
        // setScriptValue(value);
        handleScriptForTTS([]);
      };

      const handleConfirmDelete = async () => {
        try {
          const response = await store.api.delete('/chat/many/delete', {
            data: { ids: selectedChatIds },
          });
          if (response.data.success) {
            const updatedTreeData = treeData.filter(
              (node) => !selectedChatIds.includes(node.id)
            );
            setTreeData(updatedTreeData);
            setDeleted(true);
            // Optionally clear selected chat IDs after deletion
            setSelectedChatIds([]);
            setDeleteChatsModalOpen(false);

            // Check if the current chat is among the selected for deletion
            if (selectedChatIds.includes(currentChatId._id)) {
              onClick(); // Open a new chat if current chat is deleted
            }
          }
        } catch (err) {
          console.error('Failed to delete chats:', err);
        }
      };

      const toggleFavoriteMessages = () => {
        setOpenFavoriteMessages(true);
      };

      return (
        <div
          className={`z-10 dark:from-graphite-400 dark:to-graphite-400 bg-gradient-to-b h-full from-celadon-300 font-barlow to-celadon-100 lg:bg-none lg:bg-snow-400 dark:bg-graphite-800 relative lg:px-4 ${
            show ? '' : 'hidden'
          }`}
        >
          {deleteChatsModalOpen && selectedChatIds.length > 1 && (
            <DeleteChats
              onClose={() => setDeleteChatsModalOpen(false)}
              onDelete={handleConfirmDelete}
              deleted={deleted} // Pass deleted as prop
              setDeleted={setDeleted} // Pass setDeleted as prop
            />
          )}
          {deleteChatsModalOpen && selectedChatIds.length === 1 && (
            <DeleteChat
              onClose={() => setDeleteChatsModalOpen(false)}
              onDelete={handleConfirmDelete}
              deleted={deleted} // Pass deleted as prop
              setDeleted={setDeleted} // Pass setDeleted as prop
            />
          )}
          <div className="hidden lg:flex justify-between items-center pt-2">
            <p className="text-graphite-900 dark:text-celadon-700 uppercase font-bold font-lexend">
              ai chat assistant
            </p>
            <div
              onClick={() => {
                setShowConversationsSide(false);
              }}
              className="hidden lg:flex w-[20px] cursor-pointer h-[40px] bg-snow-900 dark:bg-graphite-100 rounded items-center justify-center"
            >
              <ChevronDoubleLeftIcon className="h-6 w-6 text-graphite-900 dark:text-celadon-900" />
            </div>
          </div>
          <div className="hidden justify-between items-center lg:flex mt-[12px] bg-celadon-300 dark:bg-celadon-900 px-[10px] py-[6px] rounded">
            <p className="font-lexend text-graphite-900 uppercase font-bold text-sm">
              My chats
            </p>
          </div>
          <div className="hidden lg:flex gap-2 items-center mt-[12px]">
            <div
              className="flex items-center justify-center w-[32px] h-[32px] bg-celadon-300 hover:bg-celadon-600 cursor-pointer rounded-md dark:bg-celadon-900 dark:hover:bg-celadon-100"
              onClick={onClick}
            >
              <PlusCircleIcon
                className={'h-6 w-6 text-graphite-800 dark:text-graphite-900'}
              />
            </div>
            <div
              className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 cursor-pointer rounded-md dark:bg-graphite-100 dark:hover:bg-celadon-100"
              onClick={createFolder}
            >
              <FolderPlusIcon
                className={'h-6 w-6 text-graphite-800 dark:text-celadon-900'}
              />
            </div>
            <div
              className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 cursor-pointer rounded-md dark:bg-graphite-100 dark:hover:bg-celadon-100"
              onClick={ScriptForTTS}
            >
              <SpeakerWaveIcon
                className={'h-6 w-6 text-graphite-800 dark:text-celadon-900'}
              />
            </div>
            <div
              className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 cursor-pointer rounded-md dark:bg-graphite-100 dark:hover:bg-celadon-100"
              onClick={toggleFavoriteMessages}
            >
              <StarIcon
                className={'h-6 w-6 text-graphite-800 dark:text-celadon-900'}
              />
            </div>
            <div
              onClick={() => setShowSearch(!showSearch)}
              className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 cursor-pointer rounded-md dark:bg-graphite-100 dark:hover:bg-celadon-100"
            >
              <MagnifyingGlassIcon
                className={'h-6 w-6 text-graphite-800 dark:text-celadon-900'}
              />
            </div>
            {showSearch && (
              <div className="flex w-[calc(100%-9rem)] relative">
                <input
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                  }}
                  type="text"
                  placeholder="Search"
                  className={`${
                    theme == 'dark'
                      ? 'dark:bg-graphite-900 dark:text-white'
                      : 'bg-white text-graphite-900'
                  }  text-base focus:outline-none transition flex flex-1 w-full p-0.5 rounded-lg border-2 border-snow-900 font-barlow placeholder-graphite-100`}
                  autoComplete="off"
                />
                {search && (
                  <div
                    onClick={() => setSearch('')}
                    className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 cursor-pointer rounded-md dark:bg-graphite-100 dark:hover:bg-celadon-100 absolute right-0 top-0 bottom-0"
                  >
                    <XMarkIcon
                      className={
                        'h-6 w-6 text-graphite-800 dark:text-celadon-900'
                      }
                    />
                  </div>
                )}
              </div>
            )}
            <div>
              {selectedChatIds.length > 0 && (
                <Tooltip
                  title={`Delete Selected Chat${
                    selectedChatIds.length > 1 ? 's' : ''
                  }`}
                  placement="right"
                  arrow
                >
                  <Button
                    sx={{ textTransform: 'capitalize', fontWeight: 'normal' }}
                    onClick={() => setDeleteChatsModalOpen(true)}
                    size="small"
                    variant="contained"
                    color="secondary"
                    // startIcon={<TrashIcon className="w-5 h-5" />}
                  >
                    <TrashIcon className="w-6 h-6" />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <div
            className="hidden md:block h-[2px] bg-graphite-100 my-2"
            content=" "
          />
          <div className="font-lexend font-bold uppercase py-4 text-eggplant-700 dark:text-celadon-900 text-2xl pl-4 lg:hidden">
            My chatList
          </div>
          <div className="px-4 flex gap-2 lg:hidden">
            <div
              onClick={() => toggleFavoriteMessages()}
              className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 cursor-pointer rounded-md"
            >
              <StarIcon className={'h-6 w-6 text-graphite-800'} />
            </div>
            <div
              onClick={() => setShowSearch(!showSearch)}
              className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 cursor-pointer rounded-md"
            >
              <MagnifyingGlassIcon className={'h-6 w-6 text-graphite-800'} />
            </div>
            <div className="flex w-full">
              <input
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(1);
                }}
                type="text"
                placeholder="Search"
                className={`appearance-none ${
                  theme == 'dark'
                    ? 'dark:bg-graphite-900 dark:text-white dark:focus:bg-graphite-900 dark:focus:text-white'
                    : 'bg-white text-graphite-900 focus:bg-white focus:text-eggplant-200'
                }  text-base focus:outline-none transition flex flex-1 w-full p-0.5 rounded-lg border-2 border-snow-900 font-barlow placeholder-graphite-100`}
                autoComplete="on"
              />
              {search && (
                <div
                  onClick={() => setSearch('')}
                  className="flex items-center justify-center w-[32px] h-[32px] bg-snow-900 hover:bg-snow-600 cursor-pointer rounded-md dark:bg-graphite-100 dark:hover:bg-celadon-100 absolute right-0 top-0 bottom-0"
                >
                  <XMarkIcon
                    className={
                      'h-6 w-6 text-graphite-800 dark:text-celadon-900'
                    }
                  />
                </div>
              )}
            </div>
            <div>
              {selectedChatIds.length > 0 && (
                <Tooltip
                  title={`Delete Selected Chat${
                    selectedChatIds.length > 1 ? 's' : ''
                  }`}
                  placement="right"
                  arrow
                >
                  <Button
                    sx={{ textTransform: 'capitalize', fontWeight: 'normal' }}
                    onClick={() => setDeleteChatsModalOpen(true)}
                    size="small"
                    variant="contained"
                    color="secondary"
                    // startIcon={<TrashIcon className="w-5 h-5" />}
                  >
                    <TrashIcon className="w-6 h-6" />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <>
            <div className="supports-[height:100cqh]:h-[calc(100cqh-20em)] supports-[height:100svh]:h-[calc(100cqh-20em)] lg:h-[calc(100vh-14em)] w-full">
              <div className="px-4 md:px-0 py-2 lg:py-0 rounded-l-md rounded-br-md h-[100%] w-full">
                <DragAndDropFolders onSelectChat={onSelectChat} />
              </div>
            </div>
          </>
          {showTextSpeechDialog && (
            <TextSpeechDialog
              onClose={() => setShowTextSpeechDialog(false)}
              script={''}
            />
          )}
        </div>
      );
    }
  )
);

export default ConversationSide;
