import { XMarkIcon } from '@heroicons/react/24/solid';
import { Dialog, IconButton } from '@mui/material';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const CommonModal = ({
  open,
  handleClose,
  children,
  withoutPadding = false,
  withCloseButton = false,
  withoutContainer = false,
  ...props
}) => {
  const { theme } = useThemeStore();
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      {...props}
      PaperProps={{
        sx: {
          position: 'relative',
          backgroundColor: theme == 'dark' ? '#141114' : '#F6F7F5',
          backgroundImage: 'none',
          borderRadius: '4px',
          minWidth: '400px',
          minHeight: '300px',
          ...props.sx,
        },
      }}
    >
      {/* Close button */}
      {withCloseButton && (
        <div className={cn('absolute right-8 top-4')}>
          <IconButton onClick={handleClose}>
            <XMarkIcon
              className={cn('w-6 h-6', {
                'text-graphite-900': theme !== 'dark',
                'text-white': theme === 'dark',
              })}
            />
          </IconButton>
        </div>
      )}
      <hr className="border-snow-900 border-solid bg-vanilla-900 h-1" />
      {withoutContainer ? (
        <>{children}</>
      ) : (
        <div
          className={cn(
            'flex justify-center flex-col  dark:text-white h-full w-full',
            {
              'px-6 py-4': !withoutPadding,
            }
          )}
        >
          {children}
        </div>
      )}
    </Dialog>
  );
};

export default CommonModal;
