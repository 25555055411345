import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const MiniTab = ({ title, active, onClick }) => {
  const { theme } = useThemeStore();
  const onLocalClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <button
      onClick={onLocalClick}
      className={cn(
        'px-4 py-2 rounded-md font-barlow font-bold text-xs uppercase',
        {
          'bg-vanilla-600 text-graphite-900': active && theme !== 'dark',
          'bg-graphite-600 text-white': active && theme === 'dark',
          'text-[#BCBDBA] bg-snow-900': !active && theme !== 'dark',
          'text-white': !active && theme === 'dark',
        }
      )}
    >
      {title}
    </button>
  );
};

export default MiniTab;
