import {
  BoltIcon,
  PaperClipIcon,
  RocketLaunchIcon,
  SparklesIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import useThemeStore from 'Theme/store';
import Emoji from 'react-emojis';
import { cn } from 'utils/styles';

const TemplateCard = ({
  category,
  title,
  description,
  userInfo,
  files,
  runs,
  multi_default_models,
  default_model,
  onClick,
  icon,
}) => {
  const { theme } = useThemeStore();
  // Formatter
  const formatter = new Intl.NumberFormat('en-US');
  return (
    <div
      onClick={onClick}
      className={cn(
        'flex flex-col gap-2 w-[300px] h-[170px] border rounded-lg justify-between px-3 py-[10px] cursor-pointer hover:scale-102 ',
        {
          'bg-white border-snow-900': theme !== 'dark',
          'bg-graphite-900 border-snow-100': theme === 'dark',
        }
      )}
    >
      <div className="flex items-center space-x-2 text-graphite-100 dark:text-flax-900">
        <span className="rounded-full">
          <TagIcon className={'h-4 w-6 '} />
        </span>
        <h2 className="text-[10px] font-lexend uppercase font-bold">
          {category}
        </h2>
      </div>
      <div className="flex items-center space-x-2">
        <div className="rounded-full text-4xl">
          <Emoji emoji={icon ?? 'high-voltage'} />
        </div>
        <h2 className="font-lexend text-sm font-medium leading-5 text-left capitalize text-graphite-800 dark:text-snow-100 line-clamp-2">
          {title}
        </h2>
      </div>
      <div className="flex flex-col">
        <p className="line-clamp-2 font-barlow font-normal text-xs text-graphite-600 dark:text-snow-100">
          {description}
        </p>
      </div>
      <div className="flex mt-2 space-x-2 align-middle items-center justify-between">
        <div className="max-w-[150px]">
          {multi_default_models?.length > 1 ? (
            <div className={'flex items-center'}>
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: multi_default_models
                        .map((model) => model.name)
                        .join('<br>'),
                    }}
                  />
                }
                arrow
              >
                <p className="flex items-center uppercase group-hover:bg-flax-600 bg-snow-900 px-2 rounded-md text-[9px] leading-3 w-fit font-lexend dark:bg-graphite-400 dark:text-snow-100 dark:group-hover:text-graphite-900">
                  {multi_default_models[0]?.name} +{' '}
                  {multi_default_models?.length - 1}
                  <SparklesIcon className="w-[10px] h-[10px] mr-1 ml-1" />
                </p>
              </Tooltip>
            </div>
          ) : multi_default_models?.length == 1 ? (
            <>
              <p className=" uppercase group-hover:bg-flax-600 bg-snow-900 px-2  rounded-md text-[9px] leading-3 w-fit font-lexend dark:bg-graphite-400 dark:text-snow-100 dark:group-hover:text-graphite-900">
                {multi_default_models[0]?.name}
              </p>
            </>
          ) : default_model ? (
            <>
              <p className="uppercase group-hover:bg-flax-600 bg-snow-900 px-2  rounded-md text-[9px] leading-3 w-fit font-lexend dark:bg-graphite-400 dark:text-snow-100 dark:group-hover:text-graphite-900">
                {default_model?.name}
              </p>
            </>
          ) : null}
        </div>
        <div className="flex items-center gap-1 justify-center">
          <div className="flex space-x-1 items-center">
            {userInfo !== null ? (
              userInfo?.avatar?.svg ? (
                <Tooltip
                  placement="top"
                  title={`${userInfo.fname ?? ''} ${userInfo.lname ?? ''}`}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -14],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <div className="avatar">
                    <div className="w-9 rounded">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: userInfo.avatar.svg,
                        }}
                      />
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <UserIcon className="w-5 transition rounded-full bg-gray-300" />
              )
            ) : (
              <BoltIcon className="w-5 transition rounded-full text-white bg-crayola-600" />
            )}
          </div>
          <div>
            {files.length > 0 && (
              <div className="flex space-x-1 items-center">
                <PaperClipIcon
                  className={'w-4 text-graphite-600 dark:text-snow-100'}
                />
                <span className="text-graphite-600 dark:text-snow-100 text-[0.563rem] font-lexend uppercase">
                  {files.length}
                </span>
              </div>
            )}
          </div>
          <div
            className={cn('flex space-x-1 items-center', {
              'text-snow-100': theme === 'dark',
              'text-graphite-600': theme !== 'dark',
            })}
          >
            <RocketLaunchIcon className="w-3" />
            <span className="text-[0.563rem] font-lexend uppercase">
              {runs ? formatter.format(runs) : 0} RUNS
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateCard;
