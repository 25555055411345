import { FolderIcon } from '@heroicons/react/24/solid';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const TabIcon = ({ icon, title, active, onClick }) => {
  const { theme } = useThemeStore();
  const onLocalClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <div
      onClick={onLocalClick}
      className={cn(
        ' px-4 py-2 rounded-md font-barlow font-bold text-xs uppercase cursor-pointer border w-[148px] h-[66px] flex justify-center items-center flex-col gap-[6px]',
        {
          'bg-vanilla-600 text-graphite-900 border-graphite-600':
            active && theme !== 'dark',
          'bg-graphite-600 text-white': active && theme === 'dark',
          'text-graphite-900 bg-white border-graphite-200':
            !active && theme !== 'dark',
          'text-white': !active && theme === 'dark',
        }
      )}
    >
      {icon ?? <FolderIcon className="w-4 h-4" />}
      <p className="truncate w-[130px] text-center">{title}</p>
    </div>
  );
};

export default TabIcon;
